import { Box } from "@mui/material";
import { createContext, useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../APIVar/URLConstants";
import Login from "../Pages/Desktop/Common/Login/Login";
import ResetPassword from "../Pages/Desktop/Common/Login/ResetPassword";
import EnrollmentDrive from "../Pages/Desktop/Employeev2/EnrollmentDrive/EnrollmentDrive";
import { TUserData } from "../Services/Types/TUserData";
import { permissionSlice } from "../Store/Slice_Reducer/Permission/PermissionSlice";
import { userDetailsSlice } from "../Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { useAppDispatch, useAppSelector } from "../Store/hooks";
import { Get } from "../Supporting files/CallAPI";
import { ALL_ROUTES } from "./all_routes";
import Broker_Routes from "./broker_routes";
import { EMPLOYEE_ALL_ROUTES } from "./employee_all_routes";
import Employee_Routes from "./employee_routes";
import Employer_Routes from "./employer_routes";
import useIsMobile from "../Supporting files/MobileProvider";

function Check_Route() {
  const isMobile = useIsMobile();
  const Auth = createContext("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  let token = sessionStorage.getItem("usertoken");
  const userState: TUserData = useAppSelector((state: any) => state.userDetailsSlice);
  const boxRef = useRef(null);

  useEffect(() => {
    Get(
      userDetails.userType === "BROKER"
        ? URL_CONSTANTS.get_user
        : userDetails.userType === "EMPLOYER"
          ? URL_CONSTANTS.get_user_employer
          : userDetails.userType === "EMPLOYEE"
            ? URL_CONSTANTS.get_user_employee
            : ""
    )
      .then((data: any) => {
        dispatch(userDetailsSlice.actions.setUserState(data.data.data));
        if (userDetails.userType === "BROKER") {
          dispatch(
            permissionSlice.actions.updateBrokerPermission(
              data.data.data.permission
            )
          );
        }
        if (userDetails.userType === "EMPLOYER") {
          dispatch(
            permissionSlice.actions.updateEmployerPermission(
              data.data.data.permission
            )
          );
        }
      })
      .catch((error) => {
        console.log("err", error)
        if (error.request.status === 401) {
          sessionStorage.clear();
          navigate(ALL_ROUTES.LOGIN);
        }
      });
  }, [userDetails.userType]);

  // protected routes
  const ProtectedRoutes = () => {
    if (userDetails.userType === "BROKER" && token) {
      return <Broker_Routes />;
    } else if (userDetails.userType === "EMPLOYER" && token)
      return <Employer_Routes />;
    else if (userDetails.userType === "EMPLOYEE" && token) {
      return <Employee_Routes />;
    } else {
      return (
        <>
          <Routes>
            <Route path={ALL_ROUTES.LOGIN} element={<Login />} />
            <Route path={ALL_ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={EMPLOYEE_ALL_ROUTES.ENROLLMENT} element={<EnrollmentDrive />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </>
      );
    }
  };

  const mouseMove = (e: any) => {
    if (boxRef) {
      const box: any = boxRef?.current as any
      const boxHeight = box.clientHeight
      const boxWidth = box.clientWidth
      const screenHeight = window.innerHeight;
      const mouseX = e.clientX;
      const mouseY = e.clientY
      const YDiff = screenHeight - boxHeight;
      if (mouseY > YDiff && mouseX < boxWidth) {
        box.style.opacity = 0;
        box.style.pointerEvents = 'none';
      } else {
        if (box.style.opacity = 1 && box.style.pointerEvents === 'auto') return;
        box.style.opacity = 1;
        box.style.pointerEvents = 'auto';
      }
    }
  }

  return (
    <>
      <Auth.Provider value={userDetails.userType}>
        <ProtectedRoutes />
        {!isMobile && (
          <div onMouseMove={mouseMove}>
            <Box ref={boxRef}
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                zIndex: 9990,
                background: "#e7e1f3",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "12px",
                borderRadius: "0 8px 0px 0",
                transition: "opacity 0.3s ease",
                opacity: 1,
              }}
            >
              <img src={userState.appearance.theme === 0 ?
                "../images/insurvent_logo.svg" :
                "../images/insurvent_logo_white.svg"}
                alt=""
                height={"30px"}
                style={{ maxHeight: "30px" }}
              />
            </Box>
          </div>
        )}
      </Auth.Provider>
    </>
  );
}

export default Check_Route;
