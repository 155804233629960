import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GenerateTokken, Get, Post, } from "../../../../Supporting files/CallAPI";
import { validateEmail } from "../../../../Supporting files/HelpingFunction";
import "./Login.scss";
// import { GenerateTokken, Post } from "../../../../Supporting files/CallAPI";
import WarningIcon from "@mui/icons-material/Warning";
import { EMPLOYER_URL_CONSTANTS } from "../../../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import CustomCaptcha from "../../../../Components/Common/Captcha/CustomCaptcha";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { EMPLOYEE_ALL_ROUTES } from "../../../../Routes/employee_all_routes";
import { EMPLOYER_ALL_ROUTES } from "../../../../Routes/employer_all_routes";
import { TUserData } from "../../../../Services/Types/TUserData";
import Session from "../../../../Session/Session";
import { permissionSlice } from "../../../../Store/Slice_Reducer/Permission/PermissionSlice";
import { userDetailsSlice } from "../../../../Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { useOrigin } from "../../../../Supporting files/CustomHooks";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import { login_logo } from "../../../../images/index";
import TurnstileComponent from "../../../../Components/Common/Captcha/TurnstileComponent";

export default function Login() {
  const origin = useOrigin();
  const [signInStatus, setSignInStatus] = useState(true);
  const [forgetPassswordStatus, setForgetPasswordStatus] = useState(false);
  const [checkEmailStatus, setCheckEmailStatus] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [errorforgot, setErrorforgot] = useState("");
  const [param, setParam] = useState("");
  const [paramforgot, setParamforgot] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [warnUsername, setWarnUsername] = useState(false);
  const [warnPassword, setWarnPassword] = useState(false);
  const [fade, setFade] = useState<boolean>(false);
  const [hideCaptcha, sethideCaptcha] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCaptchaVerified, setisCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);


  
  useEffect(() => {
  // if(!["https://ebdev.evervent.in","http://localhost:7000"].includes(origin)){
  //   sethideCaptcha(false)
  // }
    Session.setTokken("");
    Session.setUserType("");
    generate_tokken();
  }, []);
  // function for set state input
  function updateMasterState(attrName: any, value: string) {
    switch (attrName) {
      case setUsername:
        setUsername(value.toString().trim());
        setWarnUsername(value.toString().length > 0 ? false : true);
        break;
      case setPassword:
        setPassword(value.toString().trim());
        setWarnPassword(value.toString().length > 0 ? false : true);
        break;

      default:
        console.log(attrName, value);
        break;
    }
  }

  const validate_sign_in_form = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let warn_username = validateEmail(username.toString()) === true ? false : true;
    let warn_password = password.toString().length >= 3 ? false : true;

    setWarnUsername(warn_username);
    setWarnPassword(warn_password);

    if (warn_username === false && warn_password === false) {
      call_sign_in_api();
    }
  };

  //  login api function
  const call_sign_in_api = () => {
    let params = { username: username?.toLowerCase(), password: password ,captchaToken:captchaToken};
    Post(URL_CONSTANTS.login_via_username_password, params).then(
      (data: any) => {
        let status = data[0];
        let response = data[1];

        if (response && status === "success") {
          let user_token = response.userToken;
          sessionStorage.setItem("usertoken", user_token);
          sessionStorage.setItem("logintime", response.validUpto);
          dispatch(userDetailsSlice.actions.setUserState(response));
          get_user_permission(response.userType, response.parentsId);
          // Navigate as per the userType on behalf of the login api response
          if (response.userType === "BROKER") {
            navigate(ALL_ROUTES.DASHBOARD);
          } else if (response.userType === "EMPLOYER") {
            navigate(EMPLOYER_ALL_ROUTES.DASHBOARD);
          } else if (response.userType === "EMPLOYEE") {
            navigate(EMPLOYEE_ALL_ROUTES.DASHBOARD);
          }
        }
        if (data[0] && data[0].status === "failed") {
          let param = data[0].errors[0].param;
          let error = data[0].errors[0].message;
          setParam(param);
          setErrorLogin(error);
        }
      }
    );
  };

  const get_user_permission = (userType: any, parentsId: string) => {
    userType === "BROKER"
      ? Get(URL_CONSTANTS.get_user).then((data: any) => {
        if ((userType = "BROKER")) {
          dispatch(
            permissionSlice.actions.updateBrokerPermission(
              data.data.data.permission
            )
          );
        }
      })
      : Get(EMPLOYER_URL_CONSTANTS.get_user)
        .then((data: any) => {
          if ((userType = "BROKER" || parentsId)) {
            dispatch(
              permissionSlice.actions.updateEmployerPermission(
                data.data.data.permission
              )
            );
          }
        })
        .catch((error) => {
          if (error.response?.data.status === "failed") {
            // sessionStorage.clear();
          }
        });
  };

  //  genrate token
  const generate_tokken = () => {
    GenerateTokken(URL_CONSTANTS.generate_tokken).then((data: any) => {
      let error = data[0];
      let response = data[1];

      if (error !== "failed") {
        let access_token = response.accessToken;
        sessionStorage.setItem("access_tokken", access_token);
      }
    });
  };

  //Forgot Password
  const validate_forgot_password_form = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    let warn_username = validateEmail(username.toString()) === true ? false : true;
    setWarnUsername(warn_username);

    if (warn_username === false) {
      forgot_password_intigration();
    }
  };

  const forgot_password_intigration = () => {
    let params = { email: username?.toLowerCase() };
    Post(URL_CONSTANTS.forgot_password, params).then((data: any) => {
      let status = data[0];
      let response = data[1];
      if (status === "success") {
        setCheckEmailStatus(true);
        setForgetPasswordStatus(false);
        setFade(true);
      }
      if (status?.status === "failed") {
        let param = status.errors[0].param;
        let error = status.errors[0].message;
        setParamforgot(param);
        setErrorforgot(error);
        setFade(false);
      }
    });
  };

  let emailPrefilled: string = username;
  console.log("vcxvxc",param,errorLogin)
  return (
    <Grid className="loginWrapper" container spacing={0}>
      <Grid item xs={5} className="leftcol">
        <img
          src={
            signInStatus === true
              ? login_logo
              : forgetPassswordStatus === true
                ? "./images/reset_password.svg"
                : "./images/check_email.svg"
          }
          alt="logo"
        />
      </Grid>
      <Grid item xs={7} className="formsection">
        {signInStatus === true ? (
          <div className="innerWrapper">
            <img src={window.location.hostname === "eb.policysquare.in" || window.location.hostname === "eb.evervent.io" ? "/insurevent.png" : "/images/eb_logo.svg"} alt="Company Logo" height={"100px"} style={{ marginBottom: "20px" }} />
            <h2>Login to your account</h2>
            <p>Please login using Username & Password</p>

            {errorLogin !== "" && (
              <div className="error_display_div mt-3 mb-3">
                <div className="error_display">
                  <WarningIcon className="mr-2" /> {param === "captchaToken" ? "" : param} {errorLogin ==="captchaToken must be a string" ? "Captcha Not Verified" : errorLogin}
                </div>
              </div>)}

            <form
              onSubmit={validate_sign_in_form}
              noValidate
              className="formFields"
            >
              <RKTextField
                class_name="inputField"
                title="User ID / E-mail"
                value={username}
                attrName={setUsername}
                value_update={updateMasterState}
                warn_status={warnUsername}
                error_messg={
                  username.length === 0
                    ? "Enter your User ID / E-mail"
                    : validateEmail(username) === false
                      ? "Enter valid User ID / E-mail"
                      : ""
                }
                runOnEnter={true}
              />
              <RKTextField
                class_name="inputField mt-10"
                title="Password"
                type="password"
                value={password}
                attrName={setPassword}
                value_update={updateMasterState}
                warn_status={warnPassword}
                error_messg={
                  password.length === 0
                    ? "Enter your password."
                    : password.length < 3
                      ? "Min. 3 Character Required"
                      : ""
                }
                password_status={true}
                runOnEnter={true}
              />
              <p className="forgotpassword_link mb-4 mt-7">
                <Button
                  color="primary"
                  onClick={() => {
                    setSignInStatus(false);
                    setForgetPasswordStatus(true);
                    setUsername("");
                    setPassword("");
                    setWarnUsername(false);
                    setWarnPassword(false);
                  }}
                >
                  Forgot password?
                </Button>
              </p>
              <div className="ctaBtn">
                {(hideCaptcha || isCaptchaVerified) &&
                <Button
                  className="submitBtn"
                  size="large"
                  type="submit"
                  value="Submit"
                >
                  Sign In
                </Button>
        }
              </div>
            </form>
            <TurnstileComponent onTokenChange={setCaptchaToken} />

            {/* {!hideCaptcha && 
              <CustomCaptcha setisCaptchaVerified={setisCaptchaVerified}/>
            }
             */}

          </div>
        ) : null}
        {forgetPassswordStatus === true ? (
          <div className="innerWrapper">
            <h2>Reset your password</h2>
            <p>
              Enter the email address associated with your account and we'll
              send an email with instructions to reset your password.
            </p>
            {fade === false && errorforgot !== "" && (
              <div className="error_display_div mt-3 mb-3">
                <div className="error_display">
                  <WarningIcon className="mr-2" /> {paramforgot} {errorforgot}
                </div>
              </div>)}

            <form
              onSubmit={validate_forgot_password_form}
              noValidate
              className="formFields"
            >
              <RKTextField
                class_name="inputField"
                title="E-mail"
                value={username}
                attrName={setUsername}
                value_update={updateMasterState}
                warn_status={warnUsername}
                error_messg={
                  username.length === 0
                    ? "Enter your E-mail"
                    : validateEmail(username) === false
                      ? "Enter valid E-mail"
                      : ""
                }
              />
              <div className="ctaBtn">
                <Button
                  className="submitBtn"
                  size="large"
                  style={{ marginTop: "10px" }}
                  type="submit"
                >
                  Send
                </Button>
              </div>
              <p>
                <Button
                  color="primary"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    setSignInStatus(true);
                    setForgetPasswordStatus(false);
                    setUsername("");
                    setPassword("");
                    setWarnUsername(false);
                    setWarnPassword(false);
                  }}
                >
                  I remember my password, take me back
                </Button>
              </p>
            </form>
          </div>
        ) : null}
        {checkEmailStatus === true ? (
          <div className="innerWrapper">
            <form onSubmit={validate_forgot_password_form}>
              <h2>Check your email</h2>
              <p>We have sent a secret code on your email</p>
              <h6>{username}</h6>
              <div className="ctaBtn">
                <Button
                  className="submitBtn"
                  size="large"
                  style={{ marginTop: "10px" }}
                  href={`mailto:${username}`}
                >
                  Open mail
                </Button>
              </div>

              <p>
                <Button
                  color="primary"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    setSignInStatus(true);
                    setForgetPasswordStatus(false);
                    setCheckEmailStatus(false);
                    setUsername("");
                    setPassword("");
                  }}
                >
                  Skip
                </Button>
              </p>
              <p>
                <Button
                  color="primary"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    setCheckEmailStatus(false);
                    setForgetPasswordStatus(true);
                  }}
                >
                  Another email address
                </Button>
              </p>
              <p>
                <Button
                  color="primary"
                  style={{ marginTop: "10px" }}
                  type="submit"
                >
                  Resend Link
                </Button>
              </p>
            </form>
          </div>
        ) : null}
        <div>
        </div>
      </Grid>
    </Grid>
  );
}