import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import tickicon from "../../../../../src/images/deletePopup_tickicon.svg";
import deleteimage from "../../../../../src/images/deletepopup_deleteicon.svg";
import { docChecklistDTO } from "../../../../Services/DTO/ProductConfigurator/AddInsurerDTO";
import { ADD_NEW_INSURER } from "../../../../Services/ProductConfigurator/AddNewInsurer";
import { allowFileExtention, downloadAttachement, getFileSignedUrl, uploadImage } from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import TextAreaField from "../../../../Supporting files/TextAreaField/TextAreaField";
import "../../../../formLayout.scss";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import DocumentViewer from "../../../Common/DocumentViewer/DocumentViewer";

const DocumentChecklistView = ({
    type, role, title, checklist, tpaId
}: {
    type?: string, role?: string, title?: string, checklist?: any, tpaId?: string
}) => {
    const windowURL = window.location.search;
    const usertypeinfo = useAppSelector((state: RootState) => state.userDetailsSlice.userType);
    const params = new URLSearchParams(windowURL);
    const id: any = params.get("id");
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [selectedDocUrl, setselectedDocUrl] = useState("");
    const [docviewOpenStatus, setDocviewOpenStatus] = useState(false);
    const [openDocChecklist, setOpenDocChecklist] = useState(false);
    const [deleteStatusStep, setDeleteStatusStep] = useState(1);
    const [emailId, setemailId] = useState("");
    let [physicalFormData, setPhysicalFormData] = useState<Array<any>>([]);
    const [individualEditKey, setIndividualEditKey] = useState<{
        key: string;
        value: docChecklistDTO;
    }>();
    const [editDetailIndex, setEditDetailIndex] = useState<number | null>(null);
    const [physicalForm, setPhysicalForm] = useState<docChecklistDTO>({
        title: "",
        description: "",
        fileName: "",
        attachment: "",
        warnTitle: false,
        warnDescription: false,
        warnAttachment: false,
    });
    let [physicalData, setPhysicalData] = useState<any>([]);
    const [openPhysicalFormEdit, setopenPhysicalFormEdit] = useState<boolean>(false);
    const { BROKER } = useAppSelector((state) => state.permissionSlice);
    const [EditPermission, setEditPermission] = useState(true);

    // get detail of insurer
    useEffect(() => {
        insurerDetail();
        setEditPermission(BROKER?.ProductConfigurator.edit === undefined ? false : BROKER?.ProductConfigurator.edit);
    }, [openDocChecklist, openPhysicalFormEdit, deleteStatus]);

    const physicalPopupEdit = (index: number, data: any) => {
        setIndividualEditKey({
            key: "documentChecklist." + index,
            value: data
        });
        setopenPhysicalFormEdit(true);
        setEditDetailIndex(data.id || data._id);
        // setPhysicalForm(data);
        setPhysicalForm({
            ...physicalForm, title: data.title, description: data.description, attachment: data.attachment, fileName: data.fileName
        });
    };

    const updateMasterState = (attrName: any, value: any) => {
        let data = { ...physicalForm };
        let mainstate = attrName[0];
        let key = attrName[1];
        if (mainstate === "physicalForm") {
            data = {
                ...data,
                [key]: value,
                [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
            };
            // setPhysicalForm({
            //   ...physicalForm,
            //   [key]: value,
            //   [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
            // });
            let keydata = "documentChecklist."
            setIndividualEditKey({
                key: keydata + physicalData.length ?? 0 + 1,
                value: data,
            });

            setPhysicalForm(data);
        } else if (attrName === setEditDetailIndex) {
            attrName(value);
        } else {
            //   attrName(value);
        }
    };

    const physicalFormSave = () => {
        let data: any = physicalForm;
        data.warnTitle = physicalForm.title.length === 0 ? true : false;
        data.warnDescription = physicalForm.description.length === 0 ? true : false;
        data.warnAttachment = physicalForm.attachment.length === 0 ? true : false;
        setPhysicalData({ ...data });

        let physicalDataForm: any[] = [...physicalFormData];

        if (
            physicalForm.warnTitle === false &&
            physicalForm.warnDescription === false &&
            physicalForm.warnAttachment === false
        ) {
            const onSuccess = (res: any) => {
                toast.success(" Update successfully");
                setOpenDocChecklist(false);
                insurerDetail()
            };
            const onError = (err: any) => {
                console.log("err", err);
            };
            type === "tpa"
                ? ADD_NEW_INSURER.updateTpaIndividualEdit(
                    { key: individualEditKey?.key, value: physicalForm },
                    onSuccess,
                    {},
                    onError,
                    id
                )
                : ADD_NEW_INSURER.updateInsurerIndividualEdit(
                    { key: individualEditKey?.key, value: physicalForm },
                    onSuccess,
                    {},
                    onError,
                    id
                );

            setPhysicalFormData(physicalDataForm);
            setPhysicalForm({
                title: "",
                description: "",
                attachment: "",
                warnTitle: false,
                warnDescription: false,
                warnAttachment: false,
            });
        }
    };

    const physicalEditpopup = () => {
        let data: any = physicalForm;
        data.warnTitle = physicalForm.title === "" ? true : false;
        data.warnDescription = physicalForm.description === "" ? true : false;
        // data.warnAttachment = physicalForm.attachment === "" ? true : false;
        setPhysicalForm({ ...data });

        if (
            physicalForm.warnTitle === false &&
            physicalForm.warnDescription === false// &&
            // physicalForm.warnAttachment === false
        ) {
            if (physicalData === undefined) {
                physicalData = [];
            } else {
                if (editDetailIndex === null) {
                    // dataArray.push(-1);
                } else {
                    let foundIndex = physicalData.findIndex((e: any) => {
                        return e.id === editDetailIndex;
                    });
                    const onSuccess = (res: any) => {
                        toast.success(" Update successfully");
                        setopenPhysicalFormEdit(false);
                        setPhysicalForm({
                            title: "",
                            description: "",
                            fileName: "",
                            attachment: "",
                            warnTitle: false,
                            warnDescription: false,
                            warnAttachment: false,
                        });

                    };
                    const onError = (err: any) => {
                        console.log("err", err);
                    };

                    type === "tpa"
                        ? ADD_NEW_INSURER.updateTpaIndividualEdit(
                            {
                                key: `documentChecklist.${foundIndex}`,
                                value: physicalForm
                            },
                            onSuccess,
                            {},
                            onError,
                            id
                        )
                        : ADD_NEW_INSURER.updateInsurerIndividualEdit(
                            { key: individualEditKey?.key, value: physicalForm },
                            onSuccess,
                            {},
                            onError,
                            id
                        );
                }
            }
        }
    };

    const delete_item = () => {
        const onSuccess = (res: any) => {
            setDeleteStatusStep(2);
        };
        const onError = (err: any) => {
            console.log("err", err);
        };
        type === "tpa" ?
            ADD_NEW_INSURER.delete_tpa_item(
                id ? id : "",
                onSuccess,
                {},
                onError,
                `&item=documentChecklist&itemId=${emailId}`
            )
            :
            ADD_NEW_INSURER.delete_insurer_item(
                id ? id : "",
                onSuccess,
                {},
                onError,
                `&item=documentChecklist&itemId=${emailId}`
            );
    };

    // get insurer detail
    const insurerDetail = () => {
        const onSuccess = (res: any) => {
            let data: any = [];
            res.data.data[0].documentChecklist.forEach((e: any) => {
                data.push({ ...e, id: e._id });
            });
            setPhysicalData(data);
        };
        const onError = (err: any) => {
            console.log("err", err);
        };
        type === "tpa" ?
            ADD_NEW_INSURER.getTpaDetail(onSuccess, onError, id, usertypeinfo.toLocaleLowerCase())
            : ADD_NEW_INSURER.getInsuranceDetail(onSuccess, onError, id);

    };

    useEffect(() => {
        if (tpaId) { insurerDetail(); }
    }, [tpaId])

    return (
        <Box className={`${role === "viewOnly" ? "mb-5" : "mb-10"} detailSection`}>
            <div className="sectionTitle">
                <h4>{title ? title : "Document Checklist"}</h4>
                {role === "viewOnly" ? null : (
                    <div className="actionBtns">
                        <Link sx={{ textDecoration: "none", cursor: "pointer" }}
                            className="blueBtn addfile"
                            onClick={() => {
                                if (!EditPermission) return toast.error("Edit permissions not allowed");
                                setOpenDocChecklist(true);
                            }}
                        >
                            Add Checklist
                        </Link>
                    </div>
                )}
            </div>

            <Grid container spacing={3}>
                <Grid xs={12}>
                    <div className="table">
                        <div className="attatchment_table" style={{ overflow: "hidden" }}>
                            <ul className="heading">
                                <li>Tittle</li>
                                <li>Description</li>
                                <li>Actions</li>
                            </ul>

                            {!checklist && Array.isArray(physicalData) &&
                                physicalData.map((data: any, index: any) => (
                                    <ul key={data.id} className="subheading">
                                        <li>{data.title}</li>
                                        <li>{data.description}</li>
                                        <li
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            {role === "viewOnly" ? (
                                                <Link
                                                    className="blueBtn view"
                                                    onClick={() => {
                                                        physicalPopupEdit(index, data);
                                                    }}
                                                    sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                        marginLeft: '0 !important'
                                                    }}
                                                ></Link>
                                            ) : (
                                                <>
                                                    <Link
                                                        className="blueBtn edit"
                                                        onClick={() => {
                                                            if (!EditPermission) return toast.error("Edit permissions not allowed");
                                                            physicalPopupEdit(index, data);
                                                        }}
                                                        sx={{
                                                            textDecoration: "none",
                                                            cursor: "pointer",
                                                            marginLeft: '0 !important'
                                                        }}
                                                    ></Link>

                                                    <Link
                                                        className="redBtn delete"
                                                        onClick={() => {
                                                            if (!EditPermission) return toast.error("Edit permissions not allowed");
                                                            setemailId(data._id);
                                                            setDeleteStatus(true);
                                                        }}
                                                        sx={{
                                                            textDecoration: "none",
                                                            cursor: "pointer",
                                                        }}
                                                    ></Link>
                                                </>
                                            )}

                                            <Link
                                                className="blueBtn download ml-4"
                                                // href={data.downloadLink}
                                                onClick={()=>downloadAttachement(data.downloadLink,usertypeinfo)}
                                                target={"_blank"}
                                                sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                }}
                                            ></Link>
                                        </li>
                                    </ul>
                                ))}

                            {
                                checklist?.length > 0 && (
                                    checklist?.map((data: any, index: any) => (
                                        <ul key={data._id} className="subheading">
                                            <li>{data.title}</li>
                                            <li>{data.description}</li>
                                            <li
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                }}
                                            >
                                                <Link
                                                    className="blueBtn view"
                                                    target={"_blank"}

                                                    sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                        marginLeft: '0 !important'
                                                    }}
                                                    onClick={()=>{
                                                        setselectedDocUrl(data.attachment)
                                                        setDocviewOpenStatus(true)
                                                    }}
                                                ></Link>
                                                <Link
                                                    className="blueBtn download ml-4"
                                                    // href={data.downloadLink}
                                                    onClick={()=>downloadAttachement(data.downloadLink,usertypeinfo)}
                                                    target={"_blank"}
                                                    sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                    }}
                                                ></Link>
                                            </li>
                                        </ul>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>

            <SlidingPanel
                type={"right"}
                isOpen={openDocChecklist}
                size={75}
            >
                <div>
                    <Grid container spacing={3} alignItems="center">
                        <Grid xs={10}>
                            <h4 className="mb-0 mt-4 ml-0">Add New Checklist</h4>
                        </Grid>
                        <Grid
                            xs={2}
                            className="pt-7"
                            textAlign={"right"}
                            onClick={() => {
                                setOpenDocChecklist(false);
                                setPhysicalForm({
                                    title: "",
                                    description: "",
                                    attachment: "",
                                    warnTitle: false,
                                    warnDescription: false,
                                    warnAttachment: false,
                                });
                            }}
                        >
                            <GridCloseIcon />
                        </Grid>
                        <Grid xs={12} className="mb-4">
                            <hr />
                        </Grid>
                    </Grid>
                    <div className="scrollable_area">
                        {/* <div className="locations_section"> */}
                        <Grid container spacing={3}>
                            <Grid xs={12}>
                                {/* <p className="description">Title</p> */}
                                <RKTextField
                                    class_name="inputField"
                                    title="Title"
                                    value={physicalForm.title}
                                    attrName={["physicalForm", "title"]}
                                    value_update={updateMasterState}
                                    warn_status={physicalForm.warnTitle}
                                    error_messg="Enter Title"
                                />
                            </Grid>

                            <Grid xs={12} className="mb-0">
                                <RKTextField
                                    class_name="inputField"
                                    title="Description"
                                    value={physicalForm.description}
                                    attrName={["physicalForm", "description"]}
                                    value_update={updateMasterState}
                                    warn_status={physicalForm.warnDescription}
                                    error_messg="Enter Description"
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={0}
                            justifyContent="center"
                            className="mt-4 mb-4"
                        >
                            <Grid xs={10} className="inputField uploadFile">
                                <TextAreaField
                                    class_name="inputField"
                                    title="Add Attatchment"
                                    value={physicalForm.fileName}
                                    attrName={["physicalForm", "attachment"]}
                                    value_update={updateMasterState}
                                    warn_status={physicalForm.warnAttachment}
                                    error_messg="Add Attatchment"
                                    disable
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Button
                                    variant="contained"
                                    className="browsebtn"
                                    component="label"
                                >
                                    Browse
                                    <input
                                        hidden
                                        // accept="image/*"
                                        multiple
                                        type="file"
                                        onChange={(e: any) => {
                                            const file = e.target.files[0];
                                            const allowExt = allowFileExtention(file)
                                            if(!allowExt){
                                              toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
                                              return (e.target.value = null);
                                            }
                                            uploadImage(file, (base64Image: any) => {
                                                setPhysicalForm((physicalForm: any) => ({
                                                    ...physicalForm,
                                                    attachment: base64Image,
                                                    fileName: file?.name,
                                                }));
                                            });
                                        }}
                                    />
                                </Button>
                            </Grid>
                            <Grid xs={12}>
                                <p className="fileUploadTypeDetails">
                                    Files may be upto 50 MB, and .xls, .xlsx, .csv or .vcf formats
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={3}>
                        <Grid xs={12} className="ctaBtn mt-4">
                            <Button
                                variant="contained"
                                className="submitBtn fixedbtn"
                                onClick={physicalFormSave}
                            >
                                Add Form
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </SlidingPanel>

            <SlidingPanel
                type={"right"}
                isOpen={openPhysicalFormEdit}
                size={75}
            // backdropClicked={() => {
            //   setOpenPhysicalForm(false);
            // }}
            >
                <div>
                    <Grid container spacing={3} alignItems="center">
                        <Grid xs={10}>
                            <h4 className="mb-0 mt-4 ml-0">Edit Checklist</h4>
                        </Grid>
                        <Grid
                            xs={2}
                            className="pt-7"
                            textAlign={"right"}
                            onClick={() => {
                                setopenPhysicalFormEdit(false);
                                setPhysicalForm({
                                    title: "",
                                    description: "",
                                    attachment: "",
                                    warnTitle: false,
                                    warnDescription: false,
                                    warnAttachment: false,
                                });
                            }}
                        >
                            <GridCloseIcon />
                        </Grid>
                        <Grid xs={12} className="mb-4">
                            <hr />
                        </Grid>
                    </Grid>
                    <div className="scrollable_area">
                        {/* <div className="locations_section"> */}
                        <Grid container spacing={3}>
                            <Grid xs={12}>
                                <RKTextField
                                    class_name="inputField"
                                    title="Title"
                                    value={physicalForm.title}
                                    attrName={["physicalForm", "title"]}
                                    value_update={updateMasterState}
                                    warn_status={physicalForm.warnTitle}
                                />
                            </Grid>
                            <Grid xs={12} className="mb-0">
                                <RKTextField
                                    class_name="inputField"
                                    title="Description"
                                    value={physicalForm.description}
                                    attrName={["physicalForm", "description"]}
                                    value_update={updateMasterState}
                                    warn_status={physicalForm.warnDescription}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            justifyContent="center"
                            className="mt-4 mb-4"
                        >
                            <Grid xs={10} className="inputField uploadFile">
                                <TextAreaField
                                    class_name="inputField"
                                    title="Add Attatchment"
                                    value={physicalForm.fileName}
                                    attrName={["physicalForm", "attachment"]}
                                    value_update={updateMasterState}
                                    warn_status={physicalForm.warnAttachment}
                                    disable
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Button
                                    variant="contained"
                                    className="browsebtn"
                                    component="label"
                                >
                                    Browse
                                    <input
                                        hidden
                                        // accept="image/*"
                                        multiple
                                        type="file"
                                        onChange={(e: any) => {
                                            const file = e.target.files[0];
                                            const allowExt = allowFileExtention(file)
                                                if(!allowExt){
                                                    toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
                                                    return (e.target.value = null);
                                                }
                                            uploadImage(file, (base64Image: any) => {
                                                setPhysicalForm((physicalForm) => ({
                                                    ...physicalForm,
                                                    fileName: file.name,
                                                    attachment: base64Image,
                                                }));
                                            });
                                        }}
                                    />
                                </Button>
                            </Grid>
                            <Grid xs={12}>
                                <p className="fileUploadTypeDetails">
                                    Files may be upto 50 MB, and .xls, .xlsx, .csv or .vcf formats
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={3}>
                        <Grid xs={12} className="ctaBtn mt-4">
                            <Button
                                variant="contained"
                                className="submitBtn fixedbtn"
                                onClick={physicalEditpopup}
                            >
                                Update Checklist
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </SlidingPanel>
            <Modal open={deleteStatus} className="modalWrapper">
                <>
                    {deleteStatusStep === 1 ? (
                        <Box className="modalInner deletepopup">
                            <Grid container spacing={1}>
                                <Grid xs={12}>
                                    <Link
                                        className="close-button"
                                        onClick={() => setDeleteStatus(false)}
                                    />
                                    <div className="img-prt">
                                        <img src={deleteimage} alt="" className="img" />
                                    </div>

                                    <h5 className="popup-heading">
                                        Are you sure you want
                                        <br /> to delete this Physical form?
                                    </h5>
                                </Grid>

                                <Grid
                                    xs={12}
                                    className="ctaBtn"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        className="submitBtn"
                                        onClick={delete_item}
                                    >
                                        Yes, Delete
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDeleteStatus(false);
                                        }}
                                        variant="contained"
                                        className="cancelBtn"
                                    >
                                        cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : deleteStatusStep === 2 ? (
                        <Box className="modalInner deletepopup">
                            <Grid container spacing={1}>
                                <Grid xs={12}>
                                    <Link
                                        className="close-button"
                                        onClick={() => setDeleteStatus(false)}
                                    />
                                    <div className="img-prt">
                                        <img src={tickicon} alt="" className="img" />
                                    </div>
                                    <h5 className="popup-heading">Deleted Successfully!</h5>
                                </Grid>
                                <Grid xs={12}></Grid>
                                <Grid
                                    xs={12}
                                    className="ctaBtn"
                                    style={{ textAlign: "center" }}
                                >
                                    <Button
                                        onClick={() => {
                                            setDeleteStatus(false);
                                            setDeleteStatusStep(1);
                                        }}
                                        variant="contained"
                                        className="submitBtn"
                                    >
                                        Okay
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : null}
                </>
            </Modal>
            <DocumentViewer  url={selectedDocUrl} open={docviewOpenStatus} onClose={()=>{setDocviewOpenStatus(false)}}  />
        </Box>
    );
};

export default DocumentChecklistView;
