import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { Claim_SERVICES } from "../../../../../Services/Claim/ClaimServices";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import './ClaimPopup.scss';

interface Props {
  open_status: boolean;
  attrName: any;
  data: any;
  value_update: Function;
}

const ClaimPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  data,
}) => {
  const { formatCurrency } = useFormatCurrency();
  const [prioritystatusData, setprioritystatusData] = useState<{ label: number; value: string }[]>([]);
  const { dateFormat, timeZone } = useAppSelector((state: any) => state.userDetailsSlice.localInformation);
  const { userType } = useAppSelector((state: any) => state.userDetailsSlice);
  const steps = ["Pending", "Inprogress", "Active"];
  const [count, setCount] = useState<number>(1);
  const [additionalInfo, setAdditionalInfo] = useState<boolean>(false);
  const [info, setInfo] = useState({
    expenses: { amount: "" },
    important_dates: { claim_register_date: "" },
    important_ids: { insurance_claim_no: "" },
  });

  useEffect(() => {
    get_status();
    if (data && data?.status === 50) { // Aproved
      setCount(3)
    } else if (data && data?.status === 54 || data?.status === 55) { // Requested, Sent to TPA/Insurer
      setCount(2)
    } else {
      setCount(1)
    }
  }, [data])

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    Claim_SERVICES.get_status(onSuccess, onError);
  };

  useEffect(() => {
    if (data._id) {
      setInfo(data.tpa_claim_detail_info)
    }
  }, [data._id])

  // const detail_view_api = (id: string) => {
  //   const onSuccess = (res: any) => {
  //     setInfo(res?.data?.data[0]?.tpa_claim_detail_info);
  //   };
  //   const onError = (err: any) => {
  //     console.log("err", err);
  //   };
  //   if (data._id) {
  //     Claim_SERVICES.get_claim_detail_data(data._id, onSuccess, onError, userType.toLowerCase());
  //   }
  // };
  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Claim Detail</span>
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <div className="cashlessClaim">
                  <div className="request_heading">
                    <h5>Status</h5>
                    <span className="pending">
                      {
                        prioritystatusData?.find(
                          (item: any) => item.label === data?.status
                        )?.value
                      }
                    </span>
                  </div>
                </div>
              </Grid>
              {data?.policyNumber && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Policy Number <span>{data?.policyNumber}</span>
                  </p>
                </Grid>
              )}
              {data?.admisionDate && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Admission Date <span>
                      {date_time_format(data?.admisionDate, dateFormat, timeZone)}
                    </span>
                  </p>
                </Grid>
              )}
              {data?.reason && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Reason for Hospitalisation
                    <span>{data?.reason}</span>
                  </p>
                </Grid>
              )}
              {data?.memberId && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Member Id
                    <span>{data?.memberId + " - " + data.memberName}</span>
                  </p>
                </Grid>
              )}
              {data?.clientId && data?.clientName && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Client Id
                    <span>{`${data?.clientId} - ${data?.clientName}`}</span>
                  </p>
                </Grid>
              )}
              {data?.policyId && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Policy Id
                    <span>{data?.policyId}</span>
                  </p>
                </Grid>
              )}
              {data?.hospitalName && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Hospital
                    <span>{data?.hospitalName}</span>
                  </p>
                </Grid>
              )}
              {data?.dischargeDate && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Discharge Date
                    <span>{date_time_format(data?.dischargeDate, dateFormat, timeZone)}</span>
                  </p>
                </Grid>
              )}
              {data?.reason && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Illness Reason for Hospitalisation/Claim
                    <span>{data?.reason}</span>
                  </p>
                </Grid>
              )}
                            {data?.requestType && (
                <Grid xs={6}>
                  <p className="health_ecard">
                  Request Type
                    <span>{data?.requestType.toLowerCase() === "intimation" ? "Reimbursement" :data?.requestType}</span>
                  </p>
                </Grid>
              )}
              <Grid className="mt-3" xs={12}>
                <hr />
              </Grid>
              <Grid xs={12} className="headingTitle">
                <h3>
                  <span>Detailed Information</span>
                </h3>
              </Grid>
              <Grid xs={12}>
                <hr />
              </Grid>
              {data?.reference_no && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Claim Reference Number
                    <span>{data?.reference_no}</span>
                  </p>
                </Grid>
              )}
              {data?.balance_si && ( // not declared yet
                <Grid xs={6}>
                  <p className="health_ecard">
                    Balance SI
                    <span>{formatCurrency(`${data?.balance_si}` || "0")}</span>
                  </p>
                </Grid>
              )}
              {data?.claimAmt && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Requested Claim Amount
                    <span>{formatCurrency(`${data?.claimAmt}` || "0")}</span>
                  </p>
                </Grid>
              )}
              <Grid xs={6}>
                <p className="health_ecard">
                  Paid Claim Amount
                  <span>{formatCurrency(`${data?.paid}` || "0")}</span>
                </p>
              </Grid>
              {data?.outstanding && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Outstanding Claim Amount
                    <span>{formatCurrency(`${data.outstanding}` || "0")}</span>
                  </p>
                </Grid>
              )}
              {data?.transactionId && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Transaction ID
                    <span>{data?.transactionId}</span>
                  </p>
                </Grid>
              )}
              {data?.bank_info && (  // not declared yet
                <Grid xs={12}>
                  <p className="health_ecard">
                    Bank Info
                    <span>{data?.bank_info}</span>
                  </p>
                </Grid>
              )}
              {data?.remarks && (
                <Grid xs={12}>
                  <p className="health_ecard">
                    Remarks
                    <span>{data?.remarks}</span>
                  </p>
                </Grid>
              )}
              {data?.ailment && (  // not declared yet
                <Grid xs={12}>
                  <p className="health_ecard">
                    Ailment
                    <span>{data?.ailment}</span>
                  </p>
                </Grid>
              )}
              {data?.deficiency && (  // not declared yet
                <Grid xs={12}>
                  <p className="health_ecard">
                    Deficiency IR Requirment
                    <span>{data?.deficiency}</span>
                  </p>
                </Grid>
              )}
            </Grid>
          </Box>

          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button style={{ right: '33%' }}
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => { setAdditionalInfo(true) }}
              >
                View Additional Details
              </Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel >

      <SlidingPanel
        type={"right"}
        isOpen={additionalInfo}
        size={75}
        panelClassName="slidingPanel"
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Additional Details</span>
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link className="closeIcon"
                onClick={() => { setAdditionalInfo(false) }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Box className="detailSection" >
              <div className="sectionTitle">
                <h4>Expenses</h4>
              </div>
              <div>
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <div className="employee_table tpa_table">
                      <div className="employee_innerpart " >
                        <ul className="heading">
                          <li>Item</li>
                          <li>Value</li>
                        </ul>
                        {info?.expenses && (
                          Object.entries(info?.expenses)?.map(([keys, values]: [string, string]) => {
                            let data = keys?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                            let final = data.join(' ');
                            return (
                              <ul className="subheading">
                                <li>{final}</li>
                                <li>{formatCurrency(values)}</li>
                              </ul>
                            )
                          }))}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Box>
        </>
      </SlidingPanel>
    </>
  );
};

export default ClaimPopup;
