import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SetPasswordDTO } from "../../../../../Services/DTO/Employee/EmployeeEnrolmentDTO";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../../Services/Employee/EmployeeEnrolmentService";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import "../EnrollmentConfirmation/EnrollmentConfirmation.scss";
import { validateMobileNumber } from "../../../../../Supporting files/HelpingFunction";

interface Props {
  value_update: Function;
  userName: string;
  mob: string;
  email: string;
  userId: string;
  apiLoading: boolean;
  setApiLoading: any;
}

const EnrollmentConfirmation: React.FC<Props> = ({
  value_update,
  userName,
  mob,
  email,
  userId,
  apiLoading,
  setApiLoading,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(mob);
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidityObj, setPasswordValidityObj] = useState<{
    isValid: boolean;
    containsNameOrEmail: boolean;
    hasMinLength: boolean;
    hasSymbolOrNumber: boolean;
  }>({
    containsNameOrEmail: false,
    hasMinLength: false,
    hasSymbolOrNumber: false,
    isValid: false,
  });
  const [trueConditions, setTrueConditions] = useState(0);

  useEffect(() => {
    isValidPassword();
  }, [createPassword]);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const isValidPassword = () => {
    // Check if password contains name or email
    const containsNameOrEmail =
      createPassword?.includes(userName) || createPassword?.includes(email);

    // Check if password length is at least 8 characters
    const hasMinLength = createPassword.length >= 8;

    // Check if password contains a symbol or number
    const symbolOrNumberRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;
    const hasSymbolOrNumber = symbolOrNumberRegex.test(createPassword);

    // Determine if password is valid
    const isValid = !containsNameOrEmail && hasMinLength && hasSymbolOrNumber;

    const returnObj: any = {
      isValid,
      containsNameOrEmail,
      hasMinLength,
      hasSymbolOrNumber,
    };

    let numTrues: number = 0;

    for (let i = 0; i < Object.keys(returnObj).length; i++) {
      if (returnObj[Object.keys(returnObj)[i]]) {
        numTrues += 1;
      }
    }

    setTrueConditions(numTrues);
    setPasswordValidityObj(returnObj);
  };

  const onSubmitPassword = () => {
    if (!(trueConditions >= 3)) {
      return;
    }
    if (!confirmPassword || !createPassword || !phoneNumber) {
      return toast.warn("Please enter all values.");
    }
    if (confirmPassword !== createPassword) {
      return toast.warn("Password and confirm password not matched");
    }
    if (!validateMobileNumber(phoneNumber)) {
      return toast.warn("Invalid Phone Number");
    }
    setApiLoading(true);

    const onSuccess = (res: any) => {
      value_update("setStartSeventhScreen", true);
      setApiLoading(false);
    };
    const onError = (err: any) => {
      console.log("err",err)
      setApiLoading(false)
    };

    const dto: SetPasswordDTO = {
      confirmPassword,
      password: createPassword,
      recoveryPhoneNumber: phoneNumber,
    };

    EMPLOYEE_ENROLMENT_SERVICES.set_password(onSuccess, onError, userId, dto);
  };

  return (
    <>
      <Box className="enrollmentConfirmation_wrapper">
        <Grid container spacing={3} className="row">
          <Grid xs={12} className="mb-5">
            <div className="backBtn">
              <ArrowBackIcon
                onClick={() => {
                  value_update("setStartSixthScreenfalse", false);
                }}
              />
              <span>Back to Enrolment Confirmation</span>
            </div>
          </Grid>

          <Grid xs={12}>
            <div className="confirmation_section">
              <div className="confirmation_innerSection">
                <h5>
                  Hey {userName}, Create your password <br /> for JIO EB Portal
                </h5>
                <hr />
                <Grid container spacing={3}>
                  <Grid xs={4} className="text-right">
                    <img
                      src="./images/enrollment_confirmation_img.svg"
                      alt=""
                      height={429}
                    />
                  </Grid>
                  <Grid xs={8}>
                    <Grid container spacing={3}>
                      <Grid xs={9}>
                        <RKTextField
                          class_name="inputField"
                          title="Recovery Phone Number For Password"
                          type="phone"
                          value={phoneNumber}
                          attrName={setPhoneNumber}
                          value_update={updateMasterState}
                          warn_status={!validateMobileNumber(phoneNumber)}
                          charcterlimit={10}
                          validation_type="numeric"
                          error_messg={
                            phoneNumber.length === 0
                              ? "Enter Phone Number"
                              : "Enter valid Phone Number"
                          }
                        />
                      </Grid>
                      <Grid xs={9}>
                        <RKTextField
                          type="password"
                          password_status
                          class_name="inputField"
                          title="Create Password"
                          value={createPassword}
                          attrName={setCreatePassword}
                          value_update={updateMasterState}
                          warn_status={createPassword?.trim() === ""}
                          error_messg={"Enter Password"}
                        />
                      </Grid>
                      <Grid xs={9}>
                        <RKTextField
                          type="password"
                          password_status
                          class_name="inputField"
                          title="Confirm Password"
                          value={confirmPassword}
                          attrName={setConfirmPassword}
                          value_update={updateMasterState}
                          warn_status={confirmPassword !== createPassword}
                          error_messg={
                            confirmPassword.length > 0
                              ? "Confirm Password must match"
                              : "Enter Confirm Password"
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <div className="confirmation_content">
                          <p>
                            Password Strength:{" "}
                            {trueConditions >= 3 ? (
                              <span>Strong</span>
                            ) : trueConditions <= 1 ? (
                              <span style={{ color: "red" }}>Weak</span>
                            ) : (
                              <span style={{ color: "yellow" }}>Medium</span>
                            )}
                          </p>
                          <ul>
                            {passwordValidityObj.containsNameOrEmail ? (
                              <li className="error">
                                Must not contain your name or email
                              </li>
                            ) : (
                              <li className="success">
                                Must not contain your name or email
                              </li>
                            )}
                            {passwordValidityObj.hasMinLength ? (
                              <li className="success">At least 8 characters</li>
                            ) : (
                              <li className="error">At least 8 characters</li>
                            )}
                            {passwordValidityObj.hasSymbolOrNumber ? (
                              <li className="success">
                                Contains a symbol or a number
                              </li>
                            ) : (
                              <li className="error">
                                Contains a symbol or a number
                              </li>
                            )}
                          </ul>
                          <div className="ctaBtn mb-5">
                            <Button
                              variant="contained"
                              className="submitBtn"
                              onClick={() => {
                                if (apiLoading) {
                                  return;
                                }
                                onSubmitPassword();
                              }}
                              disabled={apiLoading}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EnrollmentConfirmation;
