import React from "react";
import "../ThankYou/ThankYou.scss";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
interface Props {
  value_update: Function;
  showPwButton: boolean;
}

const ThankYou: React.FC<Props> = ({ value_update, showPwButton }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box className="thankyou_wrapper">
        <Grid container spacing={3} className="row">
          <Grid xs={12} className="mb-5">
            {/* <div className="backBtn">
              <ArrowBackIcon
                onClick={() => {
                  value_update("setStartFifthScreenfalse", false);
                }}
              />
              <span>Back to Enrolment Confirmation</span>
            </div> */}
          </Grid>

          <Grid xs={12}>
            <div className="thankyou_section">
              <div className="thankyou_innerSection">
                <img src="./images/thankyou_image.svg" alt="" />
                <h5>Thank you for Enrolling with us!</h5>
                <p>
                  Thank you for choosing us to protect your future - <br />{" "}
                  together, we've got you covered!
                </p>
                <div className="ctaBtn mb-5">
                  {
                    showPwButton ? (
                      <Button
                        onClick={() => navigate(ALL_ROUTES.LOGIN)}
                        variant="contained"
                        className="submitBtn"
                      >
                        Let's Login
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={() => {
                          value_update("setStartSixthScreen", true);
                        }}
                      >
                        Set your Password
                      </Button>
                    )
                  }
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ThankYou;
