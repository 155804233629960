import { Box, Grid, Link, Modal } from "@mui/material";
import "./MPolicyExclusion.scss";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../../../Services/Employee/EmployeeEnrolmentService";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  policyId: string;
  maxWidth?: string;
}
const MPolicyExclusion: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  policyId,
  maxWidth,
}) => {
  const demo = [
    {
      name: "GST Exemption - Type A",
      description:
        "Exemption for GST Type A, applicable to certain goods and services that meet the criteria for reduced tax rates. This exemption helps in reducing the financial burden on small businesses and non-profit organizations.",
      _id: "662b7997b474923ed0b043df",
    },
    {
      name: "GST Exemption - Type B",
      description:
        "Exemption for GST Type B, granted to products or services that are considered essential or beneficial for public welfare. This includes educational materials and healthcare services, promoting accessibility and affordability.",
      _id: "662b79a4b474923ed0b043f2",
    },
    {
      name: "VAT Exemption - Regional",
      description:
        "Regional VAT exemption for certain areas with economic disadvantages. Aimed at stimulating economic growth and development in less economically developed regions, this exemption supports local businesses.",
      _id: "662b79b1b474923ed0b043f3",
    },
    {
      name: "Luxury Goods Tax Exclusion",
      description:
        "Exclusion from luxury goods tax for items classified as basic necessities or essential items. This measure ensures that necessary goods remain affordable for consumers while targeting luxury items for higher taxation.",
      _id: "662b79c2b474923ed0b043f4",
    },
    {
      name: "Environmental Tax Relief",
      description:
        "Relief from environmental tax for companies implementing green practices and sustainable operations. This relief encourages businesses to reduce their environmental impact and invest in eco-friendly technologies.",
      _id: "662b79d3b474923ed0b043f5",
    },
  ];
  const [exclusions, setExclusions] = useState([]);
  useEffect(() => {
    get_exclusions();
  }, [policyId]);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const get_exclusions = () => {
    const onSuccess = (res: any) => {
      setExclusions(res.data.data[0].exclusion);
    };

    const onError = (err: any) => {};

    EMPLOYEE_ENROLMENT_SERVICES.get_policy_features_exclusions(
      onSuccess,
      onError,
      policyId
    );
  };
  return (
    <Modal open={open_status} style={{ background: "white" }}>
      <Box className="box">
        <Grid container>
          <Grid xs={12} className="header">
            <h1>Features</h1>
            <button
              onClick={() => {
                attrName(false);
              }}
            >
              <Close />
            </button>
          </Grid>
          <Grid xs={12} className="">
            <ul className="exclusion_box">
              {exclusions.map((item: any) => {
                return (
                  <li className="exclusion_list">
                    <div className="exclusion_inerlist">
                      <span className="aids"></span>
                      <div className="exclusion_content">
                        <h5>{item.name || item.title}</h5>
                        <p className="mb-3">{item.description}</p>
                      </div>
                    </div>
                  </li>
                );
              })}
              <li></li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MPolicyExclusion;
