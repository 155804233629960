import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { useAppSelector } from "../../Store/hooks";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI, PutAPI } from "../API";

const create_Claim = (url: string, dto: any, onSuccess: any, onError: any) => {
  PostAPI.call(url, dto, onSuccess, {}, onError);
};

const get_claim_list = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, onError);
};

const get_claim_document_list = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, onError);
};

// common dropdown data service for the claim add form

const get_dropdown_data = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, onError);
};

// get claim detail view
const get_claim_detail_data = (
  id: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  userType: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_claim_detail_data(userType)}${id}`,
    onSuccess,
    onError
  );
};

//individual edit service
const individual_edit = (
  id: string,
  dto: { key: string; value: string },
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PutAPI.call(
    `${URL_CONSTANTS.claim_individual_edit}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const delete_attachment_claim = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  clientId: any,
  attachmentsId: any
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.delete_attachment_claim}?claimId=${clientId}&attachmentsId=${attachmentsId}`,
    {},
    onSuccess,
    {},
    onError
  );
};
//claim_attachment service
const claim_attachment = (
  usertype: any,
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PutAPI.call(
    `${URL_CONSTANTS.claim_attachment(usertype)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
// claim notes add service
const claim_add_notes = (
  usertypeinfo: string,
  id: string,
  dto: { notes: { name: string; date: Date; description: string } },
  onSuccess: any,
  onError: any
) => {

  PostAPI.call(
    `${URL_CONSTANTS.claim_add_notes(id, usertypeinfo)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const get_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_status_claim, onSuccess, {}, onError);
};

const get_physical_form_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
  policyId: string,
  user: string,
  claimtype: string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_physical_form_data(user, id, policyId, claimtype)}`,
    onSuccess,
    {},
    onError
  );
};

const update_status = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.claim_status_change}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_tpa_check_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
  user: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_tpa_check_list(user)}${id}`,
    onSuccess,
    {},
    onError
  );
};

export const Claim_SERVICES = {
  create_Claim,
  get_claim_list,
  get_claim_document_list,
  get_dropdown_data,
  get_claim_detail_data,
  individual_edit,
  claim_add_notes,
  claim_attachment,
  delete_attachment_claim,
  get_status,
  update_status,
  get_physical_form_data,
  get_tpa_check_list,
};
